import React, { useEffect, useState } from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';
import toml from 'toml';

// Component: Fetch TOML file and render data
const Home: React.FC = () => {
  const [data, setData] = useState<{ links: { title: string; url: string; image: string }[] } | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTomlData = async () => {
      try {
        const response = await fetch('/links.toml'); // Fetch the TOML file from the public directory
        const text = await response.text();
        const parsedData = toml.parse(text);
        setData(parsedData);
      } catch (err) {
        setError('Failed to load data');
        console.error('Error parsing TOML:', err);
      }
    };

    fetchTomlData();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Typography 
        variant="h3" 
        align="center" 
        gutterBottom 
        sx={{
          fontWeight: 'bold',
          color: '#ffffff',
          backgroundColor: '#555555',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          marginBottom: '20px',
          fontSize: {
            xs: '1.5rem', // Small screens
            sm: '2.5rem', // Medium screens
            md: '3rem',   // Large screens
            lg: '3.5rem'  // Extra large screens
          }
        }}
      >
        MTC Wallaby Toolkit
      </Typography>
      <Grid container spacing={3}>
        {data.links.map((link, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ height: '100%', variant: 'outlined' }}>
              <CardActionArea href={link.url} target="_self" rel="noopener noreferrer">
                <CardMedia
                  sx = {{ height: 120, objectFit: 'contain' }}
                  component="img"
                  image={link.image}
                  alt={link.title}
                />
                <CardContent>
                  <Typography variant="h5" component="div" align="center">
                    {link.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Home;
